@tailwind base;

@layer base {
  .editor-message {
    @apply border-2 border-dashed border-grayblack p-2 m-2 max-w-content mx-auto;
  }

  body * {
    @apply font-avidwalsheim;
  }

  /* custom quantity counter styles */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }

  h1,
  .h1 {
    @apply font-avidwalsheim;
    font-size: 2.625rem;
    line-height: 3.125rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.28em;
  }

  h2,
  .h2 {
    @apply font-avidwalsheim;
    font-size: 2rem;
    line-height: 2.375rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.28em;
  }

  h3,
  .h3 {
    @apply font-avidwalsheimbold;
    font-size: 1.75rem;
    line-height: 2.125rem;
  
  }

  h4,
  .h4 {
    @apply font-avidwalsheimbold;
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }

  h5,
  .h5 {
    @apply font-avidwalsheimbold;
    font-size: 1.3125rem;
    line-height: 1.5625rem;
  }

  h6,
  .h6 {
    @apply font-avidwalsheimbold;
    font-size: 1.125rem;
    line-height: 1.35rem;
  }

  .display-1 {
    @apply font-avidwalsheimlight;
    font-size: 4.5rem;
    line-height: 5.4rem;
    font-weight: 300;
    letter-spacing: 0.75rem;
    text-transform: uppercase;
  }

  .display-3 {
    @apply font-avidwalsheimlight;
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 300;
    letter-spacing: 0.75rem;
    text-transform: uppercase;
  }

  .accent-1 {
    @apply font-avidwalsheimlight;
    font-weight: 300;
    font-size: 2.75rem;
    line-height: 3.4375rem;
  }

  .accent-2 {
    @apply font-avidwalsheim;
    font-weight: 400;
    font-size: 1.25rem;
  }

  .accent-3 {
    @apply font-avidwalsheim;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.1875rem;
  }

  .intro-text-bold,
  .intro-text-bold * {
    @apply font-avidwalsheimbold;
    
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .intro-text {
    @apply font-avidwalsheim;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .intro-text-link {
    @apply font-avidwalsheim;
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-decoration: underline;
  }

  .body-link {
    @apply font-avidwalsheim;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .body-link-bold {
    @apply font-avidwalsheimbold;
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: underline;
  }

  .small-button-text-bold {
    @apply font-avidwalsheimbold;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .small-navigation-bold {
    @apply font-avidwalsheimbold;
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  .small-text {
    @apply font-avidwalsheim;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 400;
  }

  .caption {
    @apply font-avidwalsheim;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 400;
  }

  .label {
    @apply font-avidwalsheimbold;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  body,
  .body {
    @apply font-avidwalsheim;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .rte a {
    color: #194bf5;
    text-decoration: underline;
  }

  .rte a:hover {
    color: #0f2d93;
  }

  .rte a:active {
    color: #050f31;
  }
  .rte a:focus {
    outline: 2px solid #194bf5;
  }

  .rte a.btn-outline-purple,
  .rte a.btn-outline-purple:hover {
    @apply text-center align-middle whitespace-nowrap focus:outline-none focus-visible:ring-[3px] ring-primary ring-offset-[3px] font-bold text-lg leading-[1.6875rem] px-4 py-[8.5px] text-white bg-primary rounded-[4px] hover:bg-primary-dark hover:cursor-pointer active:bg-primary-darker disabled:bg-base-normal no-underline;
  }

  .rte menu,
  .rte ul {
    list-style: disc outside;
    margin-bottom: 40px;
  }

  .rte ol {
    list-style: auto;
    margin-bottom: 40px;
  }

  .rte > div > ul {
    list-style: disc outside;
    margin-bottom: 40px;
  }
  .rte > div > ol {
    margin-bottom: 40px;
  }
  .rte ul li,
  .rte > div > ul li {
    padding-left: 5px;
  }
  .richTextBl ul,
  .richTextBl ol {
    list-style: unset;
    padding-left: 24px;
  }
  /*-----------------------------------------------------*/

  .body-bold {
    @apply font-avidwalsheimbold;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  .featured-user-name {
    @apply font-avidwalsheimbold;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    font-weight: 700;
  }

  .featured-user-title {
    @apply font-avidwalsheim;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  button,
  a {
    outline-color: #194bf5;
  }

  .marketplace-page::-webkit-scrollbar {
    width: 4px;
    background-color: #1f2127;
  }

  .marketplace-page::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }

  .marketplace-page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
    background-color: transparent;
  }

  .marketplace-page a:focus {
    outline: none;
  }

  .marketplace-page button:focus {
    outline: none;
  }

  .marketplace-page .mp-product-description-item div li {
    @apply ml-5;
  }

  .avid-link-desktop .checkout--marketplace {
    padding-bottom: 50px;
  }

  /* Custom Styles; */
  .purple-underline {
    @apply decoration-4 decoration-avidpurple underline underline-offset-8;
  }

  .blipsbanner {
    @apply font-avidwalsheimlight;
  }

 details.accordionList > summary::marker,
  details.accordionList > summary::-webkit-details-marker {
    display: none;
  }
  details.reqTable > summary::marker,
  details.reqTable > summary::-webkit-details-marker {
    display: none;
  }
  details.localNavContent > summary::marker,
  details.localNavContent > summary::-webkit-details-marker {
    display: none;
  }
  /* two column vertical component */
  .twoColumnVertical .nextMedia {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .twoColumnVertical .nextMedia img {
    text-align: left;
    margin: unset;
    margin-bottom: 24px;
  }
  .twoColumnVertical .nextMedia img {
    text-align: left;
    margin: unset;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 991px) {
    h1,
    .h1 {
      @apply font-avidwalsheim;
      font-size: 2rem;
      line-height: 2.375rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    h2,
    .h2 {
      @apply font-avidwalsheim;
      font-size: 1.625rem;
      line-height: 1.9375rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    h3,
    .h3 {
      @apply font-avidwalsheimbold;
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 700;
    }

    h4,
    .h4 {
      @apply font-avidwalsheimbold;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 700;
    }

    h5,
    .h5 {
      @apply font-avidwalsheimbold;
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 500;
    }

    h6,
    .h6 {
      @apply font-avidwalsheimbold;
      font-size: 1rem;
      line-height: 1.1875rem;
      font-weight: 700;
    }

    .display-1 {
      @apply font-avidwalsheimlight;
      font-size: 2.5rem;
      line-height: 3rem;
      font-weight: 300;
    }

    .display-3 {
      @apply font-avidwalsheimlight;
      font-size: 2rem;
      line-height: 2.375rem;
      font-weight: 300;
    }

    .accent-1 {
      @apply font-avidwalsheimlight;
      font-weight: 300;
      font-size: 1.875rem;
      line-height: 2.4375rem;
    }

    .accent-2 {
      @apply font-avidwalsheim;
      font-weight: 400;
      font-size: 1rem;
    }

    .accent-3 {
      @apply font-avidwalsheim;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    .intro-text-bold,
    .intro-text-bold * {
      @apply font-avidwalsheimbold;
   
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    .intro-text {
      @apply font-avidwalsheim;
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    .intro-text-link {
      @apply font-avidwalsheim;
      font-size: 1.25rem;
      line-height: 1.875rem;
      text-decoration: underline;
    }
    /* two column vertical component */
    .twoColumnVertical .btn-link-underline {
      margin-bottom: 0px;
    }
  }
}
